<!-- eslint-disable max-len -->
<template>
  <b-card
    title="Port Forward Tunneling Instant access"
    sub-title="Securely connect to your device over the internet without port forwarding or VPNs. Conn.lt provides tunnels with customizable access controls, making it easy to expose local services globally."
    class="mb-4"
  >
    <form>
      <div v-if="!activeForwarders.length">
        <b-form-group label="Protocol">
          <b-form-radio v-model="startConfig.type" name="http" value="http">HTTP</b-form-radio>
          <b-form-radio v-model="startConfig.type" name="tcp" value="tcp">TCP</b-form-radio>
        </b-form-group>
        <div class="form-group">
          <label for="devmetaname">Local Port</label>
          <b-form-input id="devmetaname" v-model="startConfig.addr" type="text" placeholder="Address" />
        </div>
        <div class="form-group">
          <label for="devmetalocation">Conn.lt Token</label>
          <b-form-input id="devmetalocation" v-model="startConfig.token" type="text" placeholder="Token" />
        </div>
        <div class="text-center mt-2">
          <b-btn variant="success" @click="startTunnel()">Start Tunnel</b-btn>
        </div>
      </div>
      <div v-else>
        <b-alert v-for="connection of activeForwarders" :key="connection.id" style="display: block; position: relative; width: 100%; margin-bottom: 1rem" show variant="warning">
          <div v-for="forwarder of connection.forwarders" :key="forwarder.id">
            <div style="margin-bottom: 0.5rem">Listening: {{ forwarder.url }}</div>
            <b-btn variant="danger" @click="stopTunnel(connection.id, forwarder.id)">Stop Tunnel</b-btn>
          </div>
        </b-alert>
      </div>
    </form>
  </b-card>
</template>

<script>
export default {
  inject: ['$larva'],
  data() {
    return {
      activeForwarders: [],
      startConfig: {
        type: 'http',
        addr: 'localhost:3000',
        token: '',
      },
    };
  },
  async mounted() {
    await this.getTunnels();
  },
  methods: {
    async startTunnel() {
      try {
        await this.$larva.request('iot-2/cmd/startPortForward/fmt/json', { ...this.startConfig });
        await this.getTunnels();
      } catch (err) {
        this.err(err);
      }
    },
    async stopTunnel(connectionId, forwarderId) {
      try {
        await this.$larva.request('iot-2/cmd/stopPortForward/fmt/json', { connectionId, forwarderId });
        await this.getTunnels();
      } catch (err) {
        this.err(err);
      }
    },
    async getTunnels() {
      try {
        const res = await this.$larva.request('iot-2/cmd/activePortForwarders/fmt/json', {});
        if (res.connections && Array.isArray(res.connections)) {
          this.activeForwarders = res.connections;
        }
      } catch (err) {
        this.err(err);
      }
    },
    err(err) {
      // larva promise error handler
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>
